import * as api from "../api/apiService";
import { EntityConnection } from "../types";
import { billingService, PlanInfoInterface } from "./BillingService";
import {
  OrganizationInterface,
  OrganizationMemberInterface,
  OrganizationMemberRole,
  OrganizationMemberStatus,
} from "./OrganizationService";
import { UserSettings, userSettingsService } from "./UserSettingsService";

export interface AdminOrganizationFullInterface extends OrganizationInterface {
  stripeCustomerId: string,
  subscriptions: AdminSubscriptionInterface[]
  members: AdminOrganizationMemberInterface[]
}

export interface AdminSubscriptionInterface {
  id: number
  externalId: string
  startDate: string
  endDate: string
  seatCount: number
  isActive: boolean
  createdAt: string
  planInfo: PlanInfoInterface
}

export interface AdminOrganizationMemberInterface {
  id: string
  firstName: string
  lastName: string
  email: string
  invitedAt: number
  credits: {
    used: number,
    limit: number,
  }
  role: OrganizationMemberRole
  status: OrganizationMemberStatus
}

type UpdateOrganizationInputDTO = {
  name: string
  isTrialAvailable: boolean
  stripeCustomerId: string
}

class AdminService {

  mapSubscriptionToInterface = (data: any): AdminSubscriptionInterface => {
    const isTrial = data.plan.name.toLowerCase().includes("trial");
    return {
      id: data.id,
      externalId: data.externalSubscriptionId,
      startDate: data.subscriptionStartDate,
      endDate: data.subscriptionEndDate,
      seatCount: data.seatCount,
      isActive: data.isActive,
      createdAt: data.createdAt,
      planInfo : isTrial ? billingService.mapTrialPlanToInterface(data.plan) : billingService.mapPaidPlanToInterface(data.plan),
    }
  }

  mapOrganizationMemberToInterface = (data: any): AdminOrganizationMemberInterface => {
    return {
      id : data.userRef.id,
      firstName: data.userRef.firstName,
      lastName: data.userRef.lastName,
      email: data.userRef.email,
      invitedAt: data.createdAt,
      credits: {
        used: data.creditsUsage?.seat.CandidateUnlockCreditsRedeemed || 0,
        limit: data.seatParams.organizationCandidateUnlockCredits,
      },
      role: data.role.name,
      status: data.blockedAt ? OrganizationMemberStatus.Suspended : data.isActive ? OrganizationMemberStatus.Active : OrganizationMemberStatus.Deactivated,
    };
  }

  mapOrganizationToInterface = (data: any): AdminOrganizationFullInterface => {
    return {
      id: data.id,
      name: data.name,
      isTrialAvailable: data.isTrialAvailable,
      hasActiveSubscription: data.hasActiveSubscription,
      stripeCustomerId: data.stripeCustomerId,
      type: data.type,
      createdAt: data.createdAt,
      subscriptions: data.subscriptions ? data.subscriptions.map(this.mapSubscriptionToInterface) : [],
      members: data.seats ? data.seats.map(this.mapOrganizationMemberToInterface) : [],
      ownerRef: data.ownerRef
    };
  }

  fetchOrganizationAll = async (args: any): Promise<EntityConnection<OrganizationInterface>> => {
    const orgRes = await api.AdminGetOrganizations(args);
    const orgCountRes = await api.AdminGetOrganizationsCount(args);
    return {
      nodes : orgRes.data.filter(Boolean).map(this.mapOrganizationToInterface),
      pageInfo : {
        pageCount : Math.max(Math.ceil(orgCountRes.data / args.limit), 1),
        total : orgCountRes.data,
      },
    };
  };

  fetchOrganizationExtraCredits = async (organizationId: string): Promise<number> => {
    const res = await api.AdminGetOrganizationExtraCredits(organizationId);
    return res.data;
  }

  fetchOrganizationById = async (id: string): Promise<AdminOrganizationFullInterface | null> => {
    const orgRes = await api.AdminGetOrganization(id);
    return orgRes?.data ? this.mapOrganizationToInterface(orgRes.data) : null;
  }

  fetchOrganizationCreditsUsageBySeats = async (organizationId: string) => {
    const resp = await api.AdminGetOrganizationCreditsUsageBySeat(organizationId);
    return resp.data;
  };

  fetchUsersCreditsUsage = async (userIds: string[]) => {
    const promises = userIds.map(id => {
      return new Promise((resolve) => {
        this.fetchUserCreditsUsage(id).then(resolve).catch(() => resolve(null))
      })
    });
    return Promise.all(promises);
  }

  fetchUserCreditsUsage = async (userId: string) => {
    const resp = await api.AdminGetUserCreditsUsage(userId);
    return {
      ...resp.data,
      userId,
    };
  };

  updateOrganization = async (id: string, data: UpdateOrganizationInputDTO): Promise<void> => {
    return api.AdminUpdateOrganization(id, data);
  }

  addOrganizationExtraCredits = async (organizationId: string, credits: number): Promise<void> => {
    return api.AdminAddOrganizationExtraCredits(organizationId, { amount: credits });
  }

  updateUserSettings = async (userId: string, obj: UserSettings): Promise<void> => {
    const featureFlags = await this.fetchUserSettings(userId);
    // because we store boolean as string in the backend, we need to convert the boolean to string
    const data = userSettingsService.convertToString({ ...featureFlags, ...obj });
    return api.AdminCreateUserSettings(userId, data);
  }

  fetchUserSettings = async (userId: string) => {
    const res = await api.AdminGetUserSettings(userId);
    // because we store boolean as string in the backend, we need to convert the string to boolean
    return userSettingsService.convertToBoolean(res.data);
  }

  fetchOrganizationSubscriptions = async (organizationId: string, args: any): Promise<EntityConnection<AdminSubscriptionInterface>> => {
    //const orgSubRes = await api.AdminGetOrganizationSubscriptions(args);
    //return {
    //  nodes : orgSubRes.data.items.filter(Boolean).map(subscriptionService.mapToInterface),
    //  pageInfo : {
    //    pageCount : Math.max(Math.ceil(orgSubRes.data.count / args.limit), 1),
    //    total : orgSubRes.data.count,
    //  },
    //};
    return {
      nodes : [],
      pageInfo : {
        pageCount : 1,
        total : 0,
      },
    };
  }

  fetchDFYList = async (args: any) => {
    const data = await api.AdminGetDFYList(args);
    return {
      nodes : data.data,
      pageInfo : {
        pageCount : Math.max(Math.ceil(data.data.length / args.limit), 1),
        total : data.data.length,
      },
    }
  }

  fetchDFYById = (id: any) => {
    return api.AdminGetDFYById(id);
  }


  fetchOrganizationMembers = async (organizationId: string, args: any): Promise<EntityConnection<OrganizationMemberInterface>> => {
    //const orgSubRes = await api.AdminGetOrganizationMembers(args);
    //  nodes : orgSubRes.data.items.filter(Boolean).map(organizationService.mapSeatToMemberInterface),
    //  pageInfo : {
    //    pageCount : Math.max(Math.ceil(orgSubRes.data.count / args.limit), 1),
    //    total : orgSubRes.data.count,
    //  },
    //};
    return {
      nodes : [],
      pageInfo : {
        pageCount : 1,
        total : 0,
      },
    };
  }
}

export const adminService = new AdminService()