import * as React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { useAuthController } from "../../../contexts/AuthProvider";
import { useUserData } from "../../../contexts/userContext";
import PlanCard from "../../../screens/profile/ProfileBilling/BillingPayment/ChoosePlan/PlanCard/PlanCard";
import { billingService, PlanInfoInterface, PlansInfoMap } from "../../../services/BillingService";
import { OrganizationMemberInviteInterface, organizationService } from "../../../services/OrganizationService";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { injectStyleOverride } from "../../DiscoverNew/DiscoverNewWrapper";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { Loading } from "../../DiscoverNew/UI/Loading/Loading";
import PurchasePlanModal from "../../modals/PurchasePlanModal/PurchasePlanModal";
import { AuthCreateOrganization } from "../AuthCreateOrganization/AuthCreateOrganization";

import css from "./SubscriptionRequired.module.css";

export const SubscriptionRequired = () => {
  React.useEffect(() => {
    return injectStyleOverride();
  }, []);

  const history = useHistory();
  const notifications = useNotifications();
  const { user } = useAuthController();
  const { fetchUserAndUsageData } = useUserData();
  const [plans, setPlans] = React.useState<PlansInfoMap>();
  const [invitations, setInvitations] = React.useState<OrganizationMemberInviteInterface[]>();
  const [planToPurchase, setPlanToPurchase] = React.useState<PlanInfoInterface>();
  const [initializing, setInitializing] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Promise.all([
      billingService.fetchAvailablePlans(),
      organizationService.fetchMemberInvitations(),
    ]).then(([plans, invitations]) => {
      setPlans(plans);
      setInvitations(invitations);
    }).finally(() => {
      setInitializing(false);
    });
  }, []);


  if (!plans && !invitations) {
    return null;
  }

  const onActivateTrial = async () => {
    setLoading(true);
    try {
      await billingService.startFreeTrial(plans!.trial);
      await fetchUserAndUsageData();
      notifications.showSuccess("Your trial has been activated!");
      history.push(ROUTES.discover);
    } catch (err) {
      notifications.showError("Coulnd't activate trial!");
      console.error('onActivateTrial', err);
    } finally {
      setLoading(false);
    }
  };

  const renderSubscriptionRequired = () => {
    if (!plans?.trial || !plans?.sourcing) {
      return null;
    }
    if (!user.organizationV2) {
      return <AuthCreateOrganization onCreated={fetchUserAndUsageData} />;
    }
    return (
      <>
        <h2 className={css.title}>Welcome to ChatterWorks!</h2>
        <p className={css.text}>
          To start using ChatterWorks, you need an active subscription.
          <br />
          Please choose one below:
        </p>
        <div className={css.plans}>
          {(!user.organizationV2 || user.organizationV2.isTrialAvailable) && (
            <PlanCard loading={loading} data={plans.trial} onChoose={onActivateTrial} buttonTitle="Activate" />
          )}
          <PlanCard loading={loading} data={plans.sourcing} onChoose={() => setPlanToPurchase(plans.sourcing)} buttonTitle="Choose" />
            {(!!plans.sourcingM2M) && (
              <PlanCard loading={loading} data={plans.sourcingM2M} onChoose={() => setPlanToPurchase(plans.sourcingM2M)} buttonTitle="Choose" />
            )}
        </div>
      </>
    );
  };

  const onAcceptInvitation = async (id: number) => {
    setLoading(true);
    try {
      await organizationService.acceptInvitation(id);
      setLoading(false);
      notifications.showSuccess("Invitation has been accepted!");
      await fetchUserAndUsageData();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const onDeclineInvitation = async (id: number) => {
    setLoading(true);
    try {
      await organizationService.declineInvitation(id);
      setLoading(false);
      notifications.showSuccess("Invitation has been declined!");
      await fetchUserAndUsageData();
      organizationService.fetchMemberInvitations().then(setInvitations);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const renderInvitation = () => {
    if (!invitations || !invitations.length) {
      return null;
    }
    return (
      <div className={css.invitationHolder}>
        <h1 className={css.title}>Join {invitations[0].organization.name}?</h1>
        <p className={css.text}>You have been invited to join the organization.</p>
        <div className={css.invitationControls}>
          <Button onClick={() => onDeclineInvitation(invitations[0].id)} theme="outline" disabled={loading}>Decline</Button>
          <Button onClick={() => onAcceptInvitation(invitations[0].id)} disabled={loading}>Join</Button>
        </div>
      </div>
    );
  };

  return (
    <div className={css.container}>
      {initializing && <Loading />}
      {invitations?.length ? renderInvitation() : renderSubscriptionRequired()}
      {!!planToPurchase && (
        <PurchasePlanModal onClose={() => setPlanToPurchase(undefined)} plan={planToPurchase} />
      )}
    </div>
  );
};
