import React, { useEffect, useState, useRef } from "react";

import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import Table from "../../../components/project/Table/Table";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { blacklistDomainService } from "../BlacklistDomainService";
import { FilterSelectHtml } from "../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { ModalAddBlacklistDomain } from "./ModalAddBlacklistDomain/ModalAddBlacklistDomain";
import { AdminBlacklistDomainsListControlls } from "./AdminBlacklistDomainsListControlls/AdminBlacklistDomainsListControlls";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { debounce } from "lodash";
import { omit, pick } from "../../../components/DiscoverNew/util/util";
import { UrlHelper } from "../../../components/DiscoverNew/util/UrlHelper";
import { PaginationFull } from "../../../components/DiscoverNew/UI/PaginationFull/PaginationFull";

import css from "./AdminBlacklistDomainsList.module.css";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";

const sortOptions = [
  { value: "CREATED_DESC", label: "Created (desc)", field: "created_at", dir: "DESC" },
  { value: "CREATED_ASC", label: "Created (asc)", field: "created_at", dir: "ASC" },
  { value: "UPDATED_DESC", label: "Updated (desc)", field: "updated_at", dir: "DESC" },
  { value: "UPDATED_ASC", label: "Updated (asc)", field: "updated_at", dir: "ASC" },
  { value: "DOMAIN_NAME_DESC", label: "Domain (desc)", field: "domain", dir: "DESC" },
  { value: "DOMAIN_NAME_ASC", label: "Domain (asc)", field: "domain", dir: "ASC" },
];

const activeOptions = [
  { value: "ALL", label: "All" },
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

const scopeOptions = [
  { value: "", label: "-" },
  { value: "SIGN-UP", label: "Sign Up" },
  { value: "FREE-TRIAL", label: "Free Trial" },
  { value: "ALL", label: "All" },
];

const getDefaultFilters = () => {
  return {
    sort: sortOptions[0],
    active: activeOptions[0],
    scope: scopeOptions[0],
    query: "",
  };
};

const getDefaultPagination = () => {
  return {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
  };
};

const getDefaultArgs = () => {
  return {
    filters: getDefaultFilters(),
    pagination: getDefaultPagination(),
  };
};

export let AdminBlacklistDomainsList = ({ confirm, notifications }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [args, setArgs] = useState({
    filters: getDefaultFilters(),
    pagination: getDefaultPagination(),
  });

  const didMount = useRef(false);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-      U R L   S E A R C H   P A R A M S      -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};

    return new Promise((resolve) => {
      const defaultFilters = getDefaultFilters();
      const defaultPagination = getDefaultPagination();
      const updatedFilters = {
        ...defaultFilters,
        ...pick(filters, Object.keys(defaultFilters)),
        sort: filters.sort
          ? sortOptions.find((item) => item.value === filters.sort) || defaultFilters.sort
          : defaultFilters.sort,
        active: filters.active
          ? activeOptions.find((item) => item.value === filters.active) || defaultFilters.active
          : defaultFilters.active,
        scope: filters.scope
          ? scopeOptions.find((item) => item.value === filters.scope) || defaultFilters.scope
          : defaultFilters.scope,
      };
      const updatedPagination = {
        currentPage: parseInt(currentPage || defaultPagination.currentPage),
        pageSize: parseInt(pageSize || defaultPagination.pageSize),
      };
      setArgs({
        filters: updatedFilters,
        pagination: updatedPagination,
      });
      resolve();
    });
  };

  const saveSearchToUrl = () => {
    const activeFilters = pick(args.filters, Object.keys(args.filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["sort", "active", "scope"]),
      sort: activeFilters.sort.value,
      active: activeFilters.active.value,
      scope: activeFilters.scope.value,
      pageSize: args.pagination.pageSize,
      currentPage: args.pagination.currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  };

  const debouncedSaveSearchToUrl = debounce(saveSearchToUrl, 200);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-              A P I   C A L L S              -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await blacklistDomainService.fetchAll({
        query: args.filters.query,
        limit: args.pagination.pageSize,
        skip: (args.pagination.currentPage - 1) * args.pagination.pageSize,
        orderBy: args.filters.sort.field,
        sortBy: args.filters.sort.dir.toLowerCase(),
        active: args.filters.active.value.toLowerCase(),
        scope: args.filters.scope.value ? args.filters.scope.value.toLowerCase() : undefined,
      });
      setData(response);
      setLoading(false);
    } catch (err) {
      notifications.showError("Couldn't fetch the domains blacklist.");
      setError(err);
      setLoading(false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 250);

  const addDomain = async (data) => {
    try {
      await blacklistDomainService.add(data);
      setIsOpenModal(false);
      notifications.showSuccess("Domain has been blacklisted.");
      fetchData();
    } catch (err) {
      setIsOpenModal(false);
      if (err?.response?.data?.message.includes("is already blacklisted")) {
        notifications.showError("Specified domain is already blacklisted.");
      } else if (err?.response?.data?.message.includes("is not a business email")) {
        notifications.showError("Specified domain is not a business email.");
      } else {
        notifications.showError(
          err?.response?.data?.message
            ? err?.response?.data?.message.capitalize()
            : "Couldn't add the domain to the blacklist."
        );
      }
    }
  };

  const updateDomain = async (id, data) => {
    try {
      await blacklistDomainService.update(id, data);
      notifications.showSuccess("Domain has been updated.");
      fetchData();
    } catch (err) {
      notifications.showError("Couldn't update the domain", err);
    }
  };

  const enableDomain = async (id, active) => {
    try {
      await blacklistDomainService.enable(id, active);
      notifications.showSuccess("Domain has been updated.");
      fetchData();
    } catch (err) {
      notifications.showError("Couldn't update the domain", err);
    }
  };

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-            U S E   E F F E C T S            -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  useEffect(() => {
    const loadData = async () => {
      await restoreSearchFromUrl();
    };
    loadData();
  }, []);

  useEffect(() => {
    return injectStyleOverride();
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    debouncedFetchData();
    debouncedSaveSearchToUrl();
  }, [args]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-              C A L L B A C K S              -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const onChangeData = (entry, column, option) => {
    let data = {};
    data[column] = option.value;
    updateDomain(entry.id, data);
  };

  const onChangeActive = (entry, option) => {
    enableDomain(entry.id, option.value);
  };

  const onChangeFilter = (filter) => {
    setArgs((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        ...filter,
      },
      pagination: {
        ...prev.pagination,
        currentPage: 1,
      },
    }));
    
  };

  const onChangePagination = (pagination) => {
    setArgs((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        ...pagination,
      },
    }));
    saveSearchToUrl();
    fetchData();
  };

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // =-                 R E N D E R                 -=
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  const { pageSize, currentPage } = args.pagination;

  return (
    <>
      <AdminBlacklistDomainsListControlls
        data={data}
        query={args.filters.query}
        onChangeQuery={(v) => onChangeFilter({ query: v })}
        sort={args.filters.sort}
        sortOptions={sortOptions}
        onChangeSort={(v) => onChangeFilter({ sort: v })}
        active={args.filters.active}
        activeOptions={activeOptions}
        onChangeActive={(v) => onChangeFilter({ active: v })}
        scope={args.filters.scope}
        scopeOptions={scopeOptions}
        onChangeScope={(v) => onChangeFilter({ scope: v })}
        onAdd={() => setIsOpenModal(true)}
      />
      <div className={css.container}>
        <Table
          noData="No blacklisted domains found"
          // onSelectAll={}
          // isAllSelected={}
          // selected={}
          // onSelect={}
          dataObject={{
            loading: pageLoading,
            error,
            data: {
              domains: data?.nodes,
            },
          }}
          columns={[
            {
              className: css.tdDomain,
              dataIndex: "domain",
              style: { width: 250 },
              render: (v) => {
                return (
                  <div
                    title={v}
                    style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {v}
                  </div>
                );
              },
            },
            {
              className: css.tdReason,
              dataIndex: "reason",
              style: { width: 400 },
              render: (v) => {
                return (
                  <div
                    title={v}
                    style={{ maxWidth: 400, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {v}
                  </div>
                );
              },
            },
            {
              dataIndex: "scope",
              style: { width: 150 },
              render: (v, r) => {
                let scopeOptions = [
                  {
                    value: "sign-up",
                    label: "Sign Up",
                  },
                  {
                    value: "free-trial",
                    label: "Free Trial",
                  },
                  {
                    value: "all",
                    label: "All",
                  },
                ];
                return (
                  <div
                    title={v}
                    style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    <FilterSelectHtml
                      label=""
                      onChange={(nv) => onChangeData(r, "scope", nv)}
                      options={scopeOptions}
                      value={scopeOptions.find((item) => item.value === v)}
                    />
                  </div>
                );
              },
            },
            {
              dataIndex: "active",
              title: "Active State",
              style: { width: 150 },
              render: (v, r) => {
                let activeOptions = [
                  {
                    value: true,
                    label: "Active",
                  },
                  {
                    value: false,
                    label: "Inactive",
                  },
                ];
                return (
                  <div
                    title={v ? "Active" : "Inactive"}
                    style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    <FilterSelectHtml
                      label=""
                      onChange={(nv) => onChangeActive(r, nv)}
                      options={activeOptions}
                      isBoolValue={true}
                      value={v ? activeOptions[0] : activeOptions[1]}
                    />
                  </div>
                );
              },
            },
            { className: css.tdDate, dataIndex: "created_at", title: "Created", render: Formatter.fullDate },
          ]}
        />
        {data?.pageInfo?.pageCount > 1 && (
            <PaginationFull
              style={{ marginTop: 20 }}
              onChangePageSize={(pageSize) => onChangePagination({ currentPage: 1, pageSize })}
              onChangeCurrentPage={(currentPage) => onChangePagination({ currentPage })}
              currentPage={currentPage}
              pageSize={pageSize}
              pageCount={data?.pageInfo?.pageCount}
            />
        )}
      </div>
      <ModalAddBlacklistDomain
        key={"new"}
        isOpen={isOpenModal}
        onSubmit={addDomain}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

AdminBlacklistDomainsList = (function withBlacklistDomainReducer(WrappedComponent) {
  return function (props) {
    const notifications = useNotifications();
    return <WrappedComponent {...props} notifications={notifications} />;
  };
})(AdminBlacklistDomainsList);

AdminBlacklistDomainsList = withConfirm(AdminBlacklistDomainsList);
