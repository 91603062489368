import React from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";

function ModalTemplate({ title, children, onClose }) {
  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>{title}</div>
        {children}
      </div>
    </Modal>
  );
}

export default ModalTemplate;
